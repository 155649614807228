.select__control {
    border: none !important;
    border-bottom: 1px solid var(--border-light) !important;
    border-radius: 0 !important;
    &:focus{
        border: none !important;
        box-shadow: none !important;
    }
}

.select__value-container {
   font-size: 14px !important;
    padding: 5.2px 8px !important;
    padding-left: 0 !important;
    color: var(--light-text) !important;
    font-weight: 700 !important;
    text-transform: capitalize !important;
}
.select__placeholder{
    color: var(--placeholder) !important;
    text-wrap: nowrap !important;
}

.select__single-value {
    color: var(--light-text) !important;
}
.select__menu{
 font-size: 14px !important;
}
.select__indicator-separator {
    display: none;
}
