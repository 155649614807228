.patients_ui {
  .basic_details {
    p {
      font-size: var(--p);
    }

    .image_section {
      display: flex;
      align-items: start;
      gap: 2rem;
      margin-top: 0.5rem;

      .profile_pic {
        width: 149px;
        min-width: 149px;
        height: 140px;
        border: 1px solid var(--light-text);
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        overflow: hidden;

        .image_box {
          width: 100%;
          height: 78%;

          video,
          img {
            object-fit: cover;
            height: 101%;
            width: 100%;
          }
        }

        .image_buttons {
          display: flex;
          align-items: center;
          border-top: 1px solid var(--light-text);
          justify-content: space-between;
          padding: 0 0.6rem;
          min-height: 30px;

          button {
            width: 100%;

            height: 100%;
          }

          #border-left {
            border-left: 1px solid var(--light-text);
            border-radius: 0 !important;
            padding-left: 1rem !important;
          }
        }
      }

      .notes {
        padding: 1rem 0;
        border-bottom: var(--outlet-bg);
        width: 100%;

        p {
          color: var(--light-text);
        }
      }
    }
  }

  .prescription_list {
    p {
      font-size: var(--p);
    }

    a {
      text-decoration: none;
      color: var(--primary);
      font-size: var(--p);
    }
  }
}

.patient_list {
  padding: 1rem 0 !important;

  .top_bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 1.5rem 1.5rem 1.5rem;
    flex-wrap: wrap;
    row-gap: 1rem;

    .dropdown-menu {
      max-height: 400px;
      overflow-y: auto;
    }

    h2 {
      font-weight: 700;
      font-size: var(--h5);
    }

    .search_filter {
      row-gap: 1rem;

      .bg_grey {
        background-color: var(--outlet-bg);
        border-radius: 8px;
      }

      .input-group {
        margin-bottom: 0 !important;
        background-color: var(--outlet-bg);
        border-radius: 8px;
        padding-inline: 0.7rem;

        .input-group-text {
          border-bottom: 0;
          cursor: pointer;
        }

        input {
          background-color: var(--outlet-bg);
          border-bottom: 0;
        }
      }
    }
  }

  .bg_grey {
    background-color: var(--outlet-bg);
    border-radius: 8px;
  }

  .table {
    thead tr th:first-child {
      text-align: start;
      padding-left: 1.5rem !important;
    }

    tbody {
      overflow-y: auto;
      max-height: 50vh;
    }

    .address {
      text-align: center;

      p {
        font-size: var(--p);
      }
    }

    .patient_name {
      display: flex;
      align-items: center;
      gap: 1rem;
      padding-left: 1rem;

      .profile_picture {
        width: 52px;
        height: 52px;
        overflow: hidden;
        border-radius: 8px;

        img {
          object-fit: cover;
          width: 100%;
          height: 100%;
        }
      }

      .name_id {
        text-align: start;

        p {
          font-size: var(--p);
        }

        small {
          font-weight: 400;
        }
      }
    }
  }

  .no_data {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    padding: 1rem;
    width: 100%;
    min-height: 55vh;

    h2 {
      font-size: var(--h3);
    }
  }
}

.date-picker-bg {
  .date-picker .input-group .react-datepicker-wrapper input {
    color: var(--secondary);

    &::placeholder {
      color: var(--placeholder)
    }
  }
}